import React, { Children, isValidElement, cloneElement } from "react";

import Styles from "./Gallery.styles";
import { GalleryProps as Props } from "./Gallery.types";

const Gallery: React.FC<Props> = props => {
  const { maxItems = 4, children, height = 500, className } = props;
  const { fixedLayout = false } = props;
  const childrenLength = Children.count(children);
  const enhancedMaxItems = maxItems < 6 ? maxItems : 6;
  const itemsLength =
    childrenLength > enhancedMaxItems ? enhancedMaxItems : childrenLength;

  return (
    <Styles
      className={`Gallery Gallery--${itemsLength} ${className}`}
      maxItems={enhancedMaxItems}
      height={height}
      fixedLayout={fixedLayout}
    >
      <div className="Gallery__grid">
        {Children.map(children, (child, index) => {
          if (!isValidElement(child)) {
            return child;
          }

          const { props } = child;
          const { className: originalClassName } = props;

          if (index >= itemsLength) {
            return;
          }

          return cloneElement(child, {
            ...props,
            className: `Gallery__grid__item ${originalClassName}`
          });
        })}
      </div>
    </Styles>
  );
};

Gallery.defaultProps = {
  className: ""
};

export default Gallery;
