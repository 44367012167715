import styled from "styled-components";

import { GalleryStyledProps as Props } from "./Gallery.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet, mobile } = BREAKPOINTS;

const GalleryStyled = styled.div<Props>`
  display: flex;
  width: 100%;
  height: ${props => props.height}px;

  .Gallery {
    &__grid {
      display: grid;
      gap: 4rem;
      width: 100%;
      flex: 1;

      @media (max-width: ${tablet}px) {
        gap: ${(props: Props) => (props.fixedLayout ? "0" : "1.6rem")};
      }

      @media (max-width: ${mobile}px) {
        display: ${(props: Props) => (props.fixedLayout ? "flex" : "grid")};
        flex-wrap: wrap;
      }

      &__item {
        position: relative;

        @media (max-width: ${mobile}px) {
          width: ${(props: Props) => (props.fixedLayout ? "50%" : "auto")};
        }
      }
    }
  }

  &.Gallery--6 {
    @media (max-width: ${tablet}px) {
      height: auto;
    }

    .Gallery__grid {
      grid-template-columns: ${(props: Props) =>
        props.fixedLayout ? "repeat(3, 1fr)" : "repeat(5, 1fr)"};

      .Gallery__grid__item:last-child {
        grid-column: ${(props: Props) =>
          props.fixedLayout ? "span 1" : "span 5"};
      }

      @media (max-width: ${tablet}px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: ${(props: Props) =>
          props.fixedLayout ? "auto" : "repeat(2, 32rem)"};

        .Gallery__grid__item {
          padding: 0.8rem;

          &:last-child {
            grid-column: span 1;
          }
        }
      }

      @media (max-width: ${mobile}px) {
        grid-template-rows: ${(props: Props) =>
          props.fixedLayout ? "auto" : "repeat(2, 15.6rem)"};

        .Gallery__grid__item {
          padding: 0.12rem;
        }
      }
    }
  }

  &.Gallery--5 {
    @media (max-width: ${tablet}px) {
      height: auto;
    }

    .Gallery__grid {
      grid-template-columns: ${(props: Props) =>
        props.fixedLayout ? "repeat(3, 1fr)" : "repeat(4, 1fr)"};

      .Gallery__grid__item:last-child {
        grid-column: ${(props: Props) =>
          props.fixedLayout ? "span 1" : "span 4"};
      }

      @media (max-width: ${tablet}px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: ${(props: Props) =>
          props.fixedLayout ? "auto" : "repeat(2, 32rem)"};

        .Gallery__grid__item {
          padding: 0.8rem;

          &:last-child {
            grid-column: span 1;
          }
        }
      }

      @media (max-width: ${mobile}px) {
        grid-template-rows: ${(props: Props) =>
          props.fixedLayout ? "auto" : "repeat(2, 15.6rem)"};

        .Gallery__grid__item {
          padding: 0.12rem;
        }
      }
    }
  }

  &.Gallery--4 {
    @media (max-width: ${tablet}px) {
      height: auto;
    }

    .Gallery__grid {
      grid-template-columns: ${(props: Props) =>
        props.fixedLayout ? "repeat(2, 1fr)" : "repeat(3, 1fr)"};

      .Gallery__grid__item:last-child {
        grid-column: ${(props: Props) =>
          props.fixedLayout ? "span 1" : "span 3"};
      }

      @media (max-width: ${tablet}px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: ${(props: Props) =>
          props.fixedLayout ? "auto" : "repeat(2, 32rem)"};

        .Gallery__grid__item {
          padding: 0.8rem;

          &:last-child {
            grid-column: span 1;
          }
        }
      }

      @media (max-width: ${mobile}px) {
        grid-template-rows: ${(props: Props) =>
          props.fixedLayout ? "auto" : "repeat(2, 15.6rem)"};

        .Gallery__grid__item {
          padding: 0.12rem;
        }
      }
    }
  }

  &.Gallery--3 {
    @media (max-width: ${tablet}px) {
      height: auto;
    }

    .Gallery__grid {
      grid-template-columns: ${(props: Props) =>
        props.fixedLayout ? "repeat(2, 1fr)" : "1fr 2fr"};

      @media (max-width: ${tablet}px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: ${(props: Props) =>
          props.fixedLayout ? "auto" : "repeat(2, 35.6rem)"};

        .Gallery__grid__item {
          padding: 0.8rem;
        }
      }

      @media (max-width: ${mobile}px) {
        grid-template-rows: ${(props: Props) =>
          props.fixedLayout ? "auto" : "repeat(2, 15.6rem)"};
      }

      .Gallery__grid__item:nth-last-child(2) {
        grid-row: ${(props: Props) =>
          props.fixedLayout ? "span 1" : "span 2"};
      }
    }
  }

  &.Gallery--2 {
    @media (max-width: ${tablet}px) {
      height: auto;
    }

    .Gallery__grid {
      grid-template-columns: repeat(2, 1fr);

      @media (max-width: ${tablet}px) {
        grid-template-rows: ${(props: Props) =>
          props.fixedLayout ? "auto" : "repeat(2, 35.6rem)"};
        grid-template-columns: ${(props: Props) =>
          props.fixedLayout ? "repeat(2, 1fr)" : "1fr"};

        .Gallery__grid__item {
          padding: 0.8rem;
        }
      }

      @media (max-width: ${mobile}px) {
        grid-template-rows: ${(props: Props) =>
          props.fixedLayout ? "auto" : "repeat(2, 15.6rem)"};

        .Gallery__grid__item {
          padding: 0.12rem;
        }
      }
    }
  }

  &.Gallery--1 {
    @media (max-width: ${tablet}px) {
      max-height: 35.2rem;
      height: auto;
    }

    @media (max-width: ${mobile}px) {
      max-height: auto;
      height: auto;
    }

    .Gallery__grid {
      grid-template-columns: ${(props: Props) =>
        props.fixedLayout ? "repeat(2, 1fr)" : "1fr"};

      @media (max-width: ${tablet}px) {
        grid-template-columns: 32.8rem;
      }

      @media (max-width: ${mobile}px) {
        grid-template-columns: 1fr;
      }
    }
  }
`;

export default GalleryStyled;
